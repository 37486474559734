<div class="item-qty" *ngIf="productItem" [class.small]="small">
  <button
    [disabled]="
      productItem?.quantity <= productItem?.minimum_quantity &&
      enableMinQtyLimiter
    "
    class="item-qty-btn"
    [class.cursor-not-allowed]="
      productItem?.quantity <= productItem?.minimum_quantity &&
      enableMinQtyLimiter
    "
    onclick="this.nextElementSibling.stepDown(); this.nextElementSibling.dispatchEvent(new Event('change'));"
  >
    <span>-</span>
  </button>

  <input
    min="1"
    max="50"
    digitOnly
    type="number"
    id="cartquality"
    name="quantity"
    [disabled]="inputDisabled"
    (change)="onQtyChanged(productItem)"
    onKeyPress="if(this.value.length===2) return false;"
    class="form-control"
    [(ngModel)]="productItem.quantity"
  />
  <!-- (change)="onInputChanged(product)" -->
  <!-- userData?.token ? onInputChangeSubject(product) : onInputChanged(product) -->
  <!-- (loaderService.asyncLoaderState | async).show -->
  <button
    [disabled]="productItem?.quantity >= 50 && enableMaxQtyLimiter"
    class="item-qty-btn"
    [class.cursor-not-allowed]="
      productItem?.quantity >= 50 && enableMaxQtyLimiter
    "
    onclick="this.previousElementSibling.stepUp(); this.previousElementSibling.dispatchEvent(new Event('change'));"
  >
    <span>+</span>
  </button>
</div>
