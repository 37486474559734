import * as tslib_1 from "tslib";
import { isPlatformBrowser } from "@angular/common";
import { Router } from "@angular/router";
import { OnInit, EventEmitter, ElementRef, } from "@angular/core";
import { ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
export class HeaderComponent {
    constructor(cartService, apiService, router, loaderService, modalService, appService, elementRef, platformId) {
        this.cartService = cartService;
        this.apiService = apiService;
        this.router = router;
        this.loaderService = loaderService;
        this.modalService = modalService;
        this.appService = appService;
        this.elementRef = elementRef;
        this.platformId = platformId;
        this.totalValue = 0;
        this.userData = {
            token: null,
            user: null,
        };
        this.scrollTop = 0;
        this.currentRoute = '/';
        this.swiperConfig = {
            bannerSlider: {
                lazy: true,
                preloadImages: false,
                loadPrevNext: true,
                loop: true,
                slidesPerView: 1,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    hideOnClick: false
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
            },
        };
        this.showMobileMenu = false;
        this.closeResult = '';
        this.isExpanded = {};
        this.toggleCart = new EventEmitter();
        this.openMenu = new EventEmitter();
        this.logout = new EventEmitter();
        this.toggleLocation = new EventEmitter();
        router.events.subscribe((val) => {
            // see also
            // console.log(val instanceof NavigationEnd);
            this.currentRoute = router.url;
            // console.log('current route => ', this.currentRoute);
            // console.log('router => ', router.url)
            if (this.currentRoute == '/') {
                this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = 'url("../../../assets/imgs/angels/background-home.webp")';
                this.elementRef.nativeElement.ownerDocument.body.style.backgroundPosition = 'top center';
                this.elementRef.nativeElement.ownerDocument.body.style.backgroundSize = 'cover';
            }
            else {
                this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = "none";
            }
        });
    }
    set userDetails(value) {
        this.userData = value;
    }
    ngOnInit() {
        // API Requests (BROWSER)
        if (isPlatformBrowser(this.platformId)) {
            this.cartService.getCartItems();
        }
        this.getHomeSliders();
    }
    toggleCollapsibleCart() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.toggleCart.emit(true);
        });
    }
    closeModal() {
        this.modalService.dismissAll();
    }
    toggleMenu() {
        this.openMenu.emit(true);
    }
    logoutUser() {
        this.logout.emit(true);
    }
    getHomeSliders() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.apiService.request({
                    method: 'GET',
                    url: '/sliders/homepage',
                    jsona: true
                });
                this.homeBanners = res;
                // console.log('home banners => ', this.homeBanners)
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    toggleMobileMenu(state) {
        this.showMobileMenu = state;
    }
    open(content, size) {
        let options = { centered: true, ariaLabelledBy: 'modal-basic-title' };
        if (size) {
            options.size = size;
        }
        this.modalService.open(content, options).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    close(modal) {
        modal.close();
    }
    getDismissReason(reason) {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return `with: ${reason}`;
        }
    }
    openSignInModal() {
        this.modalService.dismissAll();
        this.open(this.signinModal);
    }
    openSignUpModal() {
        this.modalService.dismissAll();
        this.open(this.signupModal, 'lg');
    }
}
