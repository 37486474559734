/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./confirm-dialog.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_ConfirmDialogComponent = [i0.styles];
var RenderType_ConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
export function View_ConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "confirm-dialog-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h6", [["md-dialog-title", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["md-dialog-content", ""]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "action-buttons"], ["md-dialog-actions", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-dark"], ["md-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["autofocus", ""], ["class", "btn btn-primary"], ["md-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.confirmationMessage; _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.buttonText.cancel || "Cancel"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.buttonText.accept || "Confirm"); _ck(_v, 8, 0, currVal_3); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-dialog", [], null, [["document", "keydown.escape"]], function (_v, en, $event) { var ad = true; if (("document:keydown.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeydownHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i1.ɵdid(1, 114688, null, 0, i2.ConfirmDialogComponent, [i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmDialogComponentNgFactory = i1.ɵccf("app-confirm-dialog", i2.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
