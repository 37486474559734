<!-- <section class="block-banner">
  <div
    *ngIf="homeBanners?.media"
    [disabled]="homeBanners?.media?.length <= 1"
    [swiper]="swiperConfig.bannerSlider"
    class="swiper-container swiper-container-banner"
  >
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let banner of homeBanners?.media">
        <a href="{{ banner | image: 'url' }}">
          <picture>
            <source
              media="(min-width: 1200px)"
              [attr.srcset]="banner | image: 'src':'desktop'"
            />
            <source
              media="(min-width: 576px)"
              [attr.srcset]="banner | image: 'src':'tablet'"
            />
            <source
              media="(max-width: 576px)"
              [attr.srcset]="banner | image: 'src':'mobile'"
            />
            <img
              class="img-fluid swiper-lazy"
              [src]="banner | image: 'src':'desktop'"
              [alt]="banner | image: 'alt'"
            />
          </picture>
        </a>
      </div>
    </div>
    <div
      class="swiper-pagination"
      [hidden]="homeBanners?.media?.length <= 1"
    ></div>
  </div>
</section> -->

<!-- <div class="block-location" style="background-image: url('assets/imgs/assets/flavor_graphic.png')">
  <app-location-widget></app-location-widget>
</div> -->

<!-- <section class="block-about">
  <div class="block-wrapper">
    <div class="row m-0">
      <div class="col-lg-6 block-details">
        <div class="content">
          <div class="about-content home-block" [innerHTML]="about" #aboutBlock></div>
        </div>
      </div>
      <div class="col-lg-6 block-img">
        <div class="block__img" *ngIf="aboutHomeBanner?.media">
          <img [srcset]="aboutHomeBanner?.media | image: 'src': 'desktop'" class="img-fluid"
            [alt]="aboutHomeBanner?.media | image: 'alt'">
        </div>
      </div>
    </div>
  </div>
</section> -->
<!-- <section class="content-spacer">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 colsm-12 col-12">
        <div class="home-advertisement">
          <img src="assets/imgs/angels/menu-main.jpg" />
        </div>
      </div>
    </div>
  </div>
</section> -->

<section
  class="content-spacer"
  style="padding-bottom: 0;"
  [hidden]="!homePage?.embed_url"
>
  <div class="container" [innerHTML]="homePage?.embed_url | sanitizeHTML">
  </div>
</section>
<!-- </section> -->

<section class="content-spacer" style="padding-top: 0;">
  <div class="home-advertisement">
    <div class="container">
      <!-- tab -->
      <div class="row">
        <div
          class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
          *ngIf="homePage?.menu_media && homePage?.menu_redirect_url"
        >
          <div class="home-advertisement-tab tab-lg">
            <div
              (click)="navigate(homePage?.menu_redirect_url)"
              class="cursor-pointer"
            >
              <div class="text-container">
                <h1 class="white text-shadow-yellow">
                  {{ homePage?.menu_title }}
                </h1>
                <p>{{ homePage?.menu_sub_title }}</p>
              </div>
              <img
                [defaultImage]="
                  '/assets/imgs/angels/bg-popup.png' | image: 'relative'
                "
                [lazyLoad]="homePage?.menu_media"
              />
            </div>
          </div>
        </div>
        <br />
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div
            class="home-advertisement-tab tab-md"
            *ngIf="homePage?.store_media && homePage?.store_redirect_url"
          >
            <div
              (click)="navigate(homePage?.store_redirect_url)"
              class="cursor-pointer"
            >
              <div class="text-container">
                <h5 class="white text-shadow-yellow">
                  {{ homePage?.store_title }}
                </h5>
                <p>{{ homePage?.store_sub_title }}</p>
              </div>
              <img
                [defaultImage]="
                  '/assets/imgs/angels/bg-popup.png' | image: 'relative'
                "
                [lazyLoad]="homePage?.store_media"
              />
              <!-- <img
                [defaultImage]="
                  '/assets/imgs/angels/bg-popup.png' | image: 'relative'
                "
                [lazyLoad]="
                  'assets/imgs/angels/stores-sub.jpg' | image: 'relative'
                "
              /> -->
            </div>
          </div>
          <br />
          <div
            class="home-advertisement-tab tab-md"
            *ngIf="
              homePage?.our_story_media && homePage?.our_story_redirect_url
            "
          >
            <div
              (click)="navigate(homePage?.our_story_redirect_url)"
              class="cursor-pointer"
            >
              <div class="text-container">
                <h5 class="yellow text-shadow-red">
                  {{ homePage?.our_story_title }}
                </h5>
                <p>{{ homePage?.our_story_sub_title }}</p>
              </div>
              <img
                [defaultImage]="
                  '/assets/imgs/angels/bg-popup.png' | image: 'relative'
                "
                [lazyLoad]="homePage?.our_story_media"
              />
              <!-- <img
                [defaultImage]="
                  '/assets/imgs/angels/bg-popup.png' | image: 'relative'
                "
                [lazyLoad]="
                  'assets/imgs/angels/story-sub.jpg' | image: 'relative'
                "
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="multi-serve">
    <div class="container-fluid1">
      <div class="home-promo1" *ngIf="!!homePage?.apc_card_media">
        <!-- <img
            class="b-lazy img-fluid w-100"
            src="assets/imgs/angels/home-promo1-large.jpg"
            alt="Home Promo 1"
          /> -->
        <!-- <img
            class="b-lazy img-fluid w-100"
            [defaultImage]="
              '/assets/imgs/angels/bg-popup.png' | image: 'relative'
            "
            [lazyLoad]="
              '/assets/imgs/angels/home-promo1-large.jpg' | image: 'relative'
            "
            alt="Home Promo 1"
          /> -->
        <img
          class="b-lazy img-fluid w-100 cursor-pointer"
          [defaultImage]="
            '/assets/imgs/angels/bg-popup.png' | image: 'relative'
          "
          [lazyLoad]="homePage?.apc_card_media"
          alt="Home Promo 1"
          (click)="navigate(homePage?.apc_card_redirect_url)"
        />
      </div>
      <div class="home-promo2" *ngIf="!!homePage?.double_deal_media">
        <!-- <img
          class="b-lazy img-fluid w-100"
          src="assets/imgs/angels/home-promo2-large.jpg"
          alt="Home Promo 2"
        /> -->
        <img
          class="b-lazy img-fluid w-100 cursor-pointer"
          [defaultImage]="
            '/assets/imgs/angels/bg-popup.png' | image: 'relative'
          "
          [lazyLoad]="homePage?.double_deal_media"
          alt="Home Promo 2"
          (click)="navigate(homePage?.double_deal_redirect_url)"
        />
        <!-- <img
          class="b-lazy img-fluid w-100"
          [defaultImage]="
            '/assets/imgs/angels/bg-popup.png' | image: 'relative'
          "
          [lazyLoad]="
            '/assets/imgs/angels/home-promo2-large.jpg' | image: 'relative'
          "
          alt="Home Promo 2"
        /> -->
      </div>
    </div>
  </div>
</section>

<section class="content-spacer" *ngIf="articles?.length">
  <div class="container">
    <div class="row">
      <div class="col-12 pad-bottom-md">
        <h5 class="text-shadow-yellow">News &amp; Updates</h5>
      </div>
    </div>
    <div class="row panel-newsandupdates">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" *ngFor="let article of articles">
        <div class="panel-newsandupdates-image box-shadow-yellow">
          <picture *ngIf="article?.media[1] || article?.media[0]">
            <source
              media="(min-width: 1200px)"
              [attr.srcset]="(article?.media?.length > 1 ? article?.media[1] : article?.media[0]) | image: 'src':'desktop'"
            />
            <source
              media="(min-width: 576px)"
              [attr.srcset]="(article?.media?.length > 1 ? article?.media[1] : article?.media[0]) | image: 'src':'tablet'"
            />
            <source
              media="(max-width: 576px)"
              [attr.srcset]="(article?.media?.length > 1 ? article?.media[1] : article?.media[0]) | image: 'src':'mobile'"
            />
            <img class="fullwidth" [src]="(article?.media?.length > 1 ? article?.media[1] : article?.media[0]) | image: 'src':'desktop'" [alt]="(article?.media?.length > 1 ? article?.media[1] : article?.media[0])  | image: 'alt'">
          </picture>
          <!-- <img
            [defaultImage]="
              '/assets/imgs/angels/bg-popup.png' | image: 'relative'
            "
            [lazyLoad]="'assets/imgs/angels/news1.jpg' | image: 'relative'"
            class="img-fluid fullwidth"
          /> -->
        </div>
        <a [routerLink]="'/news/' + article?.id"
          ><div class="pad-top-md pad-bottom-sm">
            <h5>{{article?.title}}</h5>
          </div></a
        >
        <div class="font-normal lineheight-md" style="text-overflow: ellipsis; overflow: hidden; height: 72px;" [innerHTML]="article?.content">
        </div>
        <div class="pad-bottom-md display-on-mobile">&nbsp;</div>
      </div>
      <!-- <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="panel-newsandupdates-image box-shadow-yellow">
          <img
            [defaultImage]="
              '/assets/imgs/angels/bg-popup.png' | image: 'relative'
            "
            [lazyLoad]="'assets/imgs/angels/news1.jpg' | image: 'relative'"
            class="img-fluid fullwidth"
          />
        </div>
        <a routerLink="/"
          ><div class="pad-top-md pad-bottom-sm">
            <h5>Promo Alert: 20% discount</h5>
          </div></a
        >
        <div class="font-normal lineheight-md">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
          lobortis risus interdum tellus convallis rhoncus.
        </div>
        <div class="pad-bottom-md display-on-mobile">&nbsp;</div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="panel-newsandupdates-image box-shadow-yellow">
          <img
            [defaultImage]="
              '/assets/imgs/angels/bg-popup.png' | image: 'relative'
            "
            [lazyLoad]="'assets/imgs/angels/news2.jpg' | image: 'relative'"
            class="img-fluid fullwidth"
          />
        </div>
        <a routerLink="/"
          ><div class="pad-top-md pad-bottom-sm">
            <h5>New Store Opening: Mandaluyong</h5>
          </div></a
        >
        <div class="font-normal lineheight-md">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
          lobortis risus interdum tellus convallis rhoncus.
        </div>
      </div> -->
    </div>
  </div>
</section>

<!-- <section class="home-gallery">
  <div class="container">
    <div class="row pad-bottom-xl">
      <div
        class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 pad-left-mobile-lg"
      >
        <div
          class="
            text-size-lg
            font-thin
            text-color-lightergrey
            pad-top-lg pad-bottom-xs
          "
        >
          @angelspizzaph
        </div>
        <h4 class="text-shadow-yellow pad-bottom-md">Gallery</h4>
      </div>
      <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
        <div
          id="carouselIndicators2"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="gallery-slide">
                <div class="row nopadding">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                    <a
                      href="https://www.instagram.com/angelspizzaph/"
                      target="_blank"
                    >
                      <div class="gallery-slide-product box-shadow-yellow">
                        <img
                          [defaultImage]="
                            '/assets/imgs/angels/bg-popup.png'
                              | image: 'relative'
                          "
                          [lazyLoad]="
                            '/assets/imgs/angels/gallery/gallery1x315.jpg'
                              | image: 'relative'
                          "
                          class="img-fluid"
                        />
                      </div>
                    </a>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                    <a
                      href="https://www.instagram.com/angelspizzaph/"
                      target="_blank"
                    >
                      <div
                        class="
                          gallery-slide-product
                          changepos
                          box-shadow-yellow
                        "
                      >
                        <img
                          [defaultImage]="
                            '/assets/imgs/angels/bg-popup.png'
                              | image: 'relative'
                          "
                          [lazyLoad]="
                            'assets/imgs/angels/gallery/gallery2x315.jpg'
                              | image: 'relative'
                          "
                          class="img-fluid"
                        />
                      </div>
                    </a>
                  </div>
                  <div class="col-xl-4 col-lg-4 hide-on-mobile">
                    <a
                      href="https://www.instagram.com/angelspizzaph/"
                      target="_blank"
                    >
                      <div class="gallery-slide-product box-shadow-yellow">
                        <img
                          [defaultImage]="
                            '/assets/imgs/angels/bg-popup.png'
                              | image: 'relative'
                          "
                          [lazyLoad]="
                            'assets/imgs/angels/gallery/gallery3x315.jpg'
                              | image: 'relative'
                          "
                          class="img-fluid"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

             <div class="carousel-item">
              <div class="gallery-slide">
                <div class="row nopadding">
                  <div class="col-xl-4 col-lg-4 hide-on-mobile">
                    <a
                      href="https://www.instagram.com/angelspizzaph/"
                      target="_blank"
                    >
                      <div
                        class="
                          gallery-slide-product
                          changepos
                          box-shadow-yellow
                        "
                      >
                        <img
                          [defaultImage]="
                            '/assets/imgs/angels/bg-popup.png'
                              | image: 'relative'
                          "
                          [lazyLoad]="
                            'assets/imgs/angels/gallery/gallery4.jpg'
                              | image: 'relative'
                          "
                          class="img-fluid"
                        />
                      </div>
                    </a>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                    <a
                      href="https://www.instagram.com/angelspizzaph/"
                      target="_blank"
                    >
                      <div class="gallery-slide-product box-shadow-yellow">
                        <img
                          [defaultImage]="
                            '/assets/imgs/angels/bg-popup.png'
                              | image: 'relative'
                          "
                          [lazyLoad]="
                            'assets/imgs/angels/gallery/gallery5.jpg'
                              | image: 'relative'
                          "
                          class="img-fluid"
                        />
                      </div>
                    </a>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                    <a
                      href="https://www.instagram.com/angelspizzaph/"
                      target="_blank"
                    >
                      <div
                        class="
                          gallery-slide-product
                          changepos
                          box-shadow-yellow
                        "
                      >
                        <img
                          [defaultImage]="
                            '/assets/imgs/angels/bg-popup.png'
                              | image: 'relative'
                          "
                          [lazyLoad]="
                            'assets/imgs/angels/gallery/gallery6.jpg'
                              | image: 'relative'
                          "
                          class="img-fluid"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a
            class="carousel-control-prev furtheradjust"
            href="#carouselIndicators2"
            role="button"
            data-slide="prev"
          ></a>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- <section
  class="block-order"
  style="background-image: url('assets/imgs/background/wood_bg.jpg')"
>
  <div class="block-wrapper">
    <div class="row">
      <div class="col-md-4 item">
        <div class="item-wrapper">
          <div class="item__img">
            <picture>
              <img
                class="img-fluid"
                [src]="homeDetails?.image_1"
                [alt]="homeDetails?.content_1"
                *ngIf="homeDetails?.image_1"
              />
            </picture>
          </div>
          <h3
            class="section--title text-light text-uppercase"
            [innerHTML]="homeDetails?.content_1 | htmlStripper"
          ></h3>
        </div>
      </div>
      <div class="col-md-4 item">
        <div class="item-wrapper">
          <div class="item__img">
            <picture>
              <img
                class="img-fluid"
                [src]="homeDetails?.image_2"
                [alt]="homeDetails?.content_2"
                *ngIf="homeDetails?.image_2"
              />
            </picture>
          </div>
          <h3
            class="section--title text-light text-uppercase"
            [innerHTML]="homeDetails?.content_2 | htmlStripper"
          ></h3>
        </div>
      </div>
      <div class="col-md-4 item">
        <div class="item-wrapper">
          <div class="item__img">
            <picture>
              <img
                class="img-fluid"
                [src]="homeDetails?.image_3"
                [alt]="homeDetails?.content_3"
                *ngIf="homeDetails?.image_3"
              />
            </picture>
          </div>
          <h3
            class="section--title text-light text-uppercase"
            [innerHTML]="homeDetails?.content_3 | htmlStripper"
          ></h3>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- <section
  [class.sticky-btn]="scrollConfig.scrollTop >= scrollConfig.scrollHeight"
  class="block__ordernow d-flex justify-content-center"
>
  <a [routerLink]="['/use-my-coupon/1']" class="mr-2 use-coupon">
    <button class="btn btn-coupon">Use My Coupon</button>
  </a>
  <a [routerLink]="'/menu/1'">
    <img
      src="assets/imgs/wz_orderNow_150x59.gif"
      alt=""
      class="img-fluid btn-orderNow"
    />
  </a>
</section> -->
