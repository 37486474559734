import * as tslib_1 from "tslib";
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd } from '@angular/router';
import { Renderer2, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
export class AppComponent {
    constructor(config, cookie, modalService, apiService, storeService, userService, router, route, loaderService, scrollListenerService, platfromId, renderer, cartService, branchLocationService, appService, windowResizeService, injector, swUpdate, cdr) {
        this.cookie = cookie;
        this.modalService = modalService;
        this.apiService = apiService;
        this.storeService = storeService;
        this.userService = userService;
        this.router = router;
        this.route = route;
        this.loaderService = loaderService;
        this.scrollListenerService = scrollListenerService;
        this.platfromId = platfromId;
        this.renderer = renderer;
        this.cartService = cartService;
        this.branchLocationService = branchLocationService;
        this.appService = appService;
        this.windowResizeService = windowResizeService;
        this.injector = injector;
        this.swUpdate = swUpdate;
        this.cdr = cdr;
        this.title = 'food-ordering-pwa';
        this.showAsyncLoader = false;
        this.showLoader = true;
        this.scrollTop = 0;
        this.userData = {
            token: null,
            user: null
        };
        // location
        this.locationState = false;
        // menu variables
        this.menuState = false;
        this.menus = [
            {
                title: 'Home',
                slug: '/',
                target: '_self'
            },
            {
                title: 'Menu',
                slug: '/menu/1',
                target: '_self',
            },
            // {
            //   title: 'Promo',
            //   slug: '/promo/1',
            //   target: '_self',
            // },
            {
                title: 'Stores',
                slug: '/locations',
                target: '_self',
            },
            {
                title: 'News',
                slug: '/news',
                target: '_self',
            },
            // {
            //   title: 'APC Card',
            //   slug: '/apc-card',
            //   target: '_self',
            // },
            // {
            //   title: 'Flavors',
            //   slug: '/flavors',
            //   target: '_self',
            // },
            // {
            //   title: 'Our Story',
            //   slug: '/about-us',
            //   target: '_self'
            // },
            {
                title: 'Contact Us',
                slug: '/contact-us',
                target: '_self',
            },
        ];
        // Cart variables
        this.isCartOpen = false;
        // customize default values of modals used by this component tree
        config.backdrop = 'static';
        config.keyboard = false;
        // this.router.events.subscribe((val) => {
        //   console.log(val instanceof NavigationEnd);
        //   console.log('val => ', val);
        //   const {url}:any = val
        //   if (val instanceof NavigationEnd && url) {
        //     let [path] = url.split('/').filter(x => !!x);
        //     console.log('path =>', path)
        //     let color = '#fff'
        //     // switch(path) {
        //     //   case "apc-card":
        //     //     document.body.style.background = 'white';
        //     //     break;
        //     //   default:
        //     //     document.body.style.background = 'transparent';
        //     // }
        //   }
        // })
    }
    onScroll(event) {
        this.scrollTop = window.scrollY;
        this.scrollListenerService.scrollListenerSubject.next(this.scrollTop);
    }
    ngOnInit() {
        this.appService.getAppSettings();
        this.cartService.getBuy1Take1Details();
        if (isPlatformBrowser(this.platfromId)) {
            this.swUpdateChecker();
            this.loadOnBrowser();
            this.onRefreshToken();
            // this.getProvinces();
            this.getCategories();
            this.storageDataInit();
            this.injector.get(NgcCookieConsentService);
            this.branchLocationService.getStoresUrl();
        }
        this.route.queryParams.subscribe(params => {
            if (!params.irclickid)
                return;
            const nextMonth = new Date();
            nextMonth.setDate(nextMonth.getDate() + 30);
            this.cookie.set('irclickid', params.irclickid, nextMonth);
        });
        // this.checkCookieConsent();
    }
    loadOnBrowser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // get branches and assign a default branch in the service (removed)
                // await this.apiService.getBranches();
                yield this.userService.getUser();
                yield this.apiService.getSocialLinks();
                // const branch = await this.storeService.getItem('branch');
                // if (!branch) {
                //   this.storeService.setItem('branch', 'araneta');
                // }
                this.showLoader = false;
            }
            catch (err) {
                this.showLoader = false;
            }
            this.loaderService.loaderState
                .subscribe((state) => {
                this.showLoader = state.show;
                if (state.show) {
                    this.renderer.removeClass(document.body, 'page-loaded');
                }
                else {
                    this.renderer.addClass(document.body, 'page-loaded');
                }
            });
            this.loaderService.asyncLoaderState
                .subscribe(state => {
                this.showAsyncLoader = state.show;
            });
            this.userService.userSubject.subscribe((user) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                // this.branchLocationService.setUserBranchLocation();
                this.userData.user = user;
                this.userData.token = user ? yield this.storeService.getItem('token') : null;
            }));
            this.cartService.cartMenuMobileOpen.subscribe(state => {
                this.toggleCart(state);
            });
            this.routeLoaderEventInit();
        });
    }
    routeLoaderEventInit() {
        if (isPlatformBrowser(this.platfromId)) {
            let prevUrl = this.router.url;
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    console.log(event.url);
                    this.onRefreshToken();
                    if (prevUrl !== event.url && !event.url.includes('/menu')) {
                        this.loaderService.show();
                    }
                    prevUrl = event.url;
                    // (document.querySelector('.page-loader') as HTMLElement).classList.remove('hide');
                }
            });
        }
    }
    onRefreshToken() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const refreshToken = yield this.storeService.getItem('refresh_token');
            const loginTimestamp = yield this.storeService.getItem('login_timestamp');
            if (refreshToken && loginTimestamp) {
                const baseTimeMin = 30;
                const timeDiffMin = moment().diff(moment(loginTimestamp), 'minutes');
                if (baseTimeMin < timeDiffMin) {
                    try {
                        const res = yield this.userService.refreshToken();
                        switch (res) {
                            case 'success':
                                yield this.storeService.setItem('login_timestamp', moment().format());
                                // window.location.reload();
                                break;
                            case 'revoked':
                                this.userService.revokeAuth();
                                break;
                        }
                        return;
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
            }
        });
    }
    open(content, size) {
        let options = { centered: true, ariaLabelledBy: 'modal-basic-title' };
        if (size) {
            options.size = size;
        }
        this.modalService.open(content, options);
    }
    // MENU 
    getCategories() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.cartService.isBranchValid();
                const branch = yield this.storeService.getItem('branch');
                const headers = {
                    'x-branch': branch || 'all'
                };
                const apcCardCode = yield this.storeService.getItem("apc");
                const res = yield this.apiService.request({
                    method: 'GET',
                    url: `/product-categories?limit=0&orderBy=order_column${this.cartService.hasApcCategory() || apcCardCode ? '&except_apc_double_bundle=1' : ''}`,
                    headers,
                    jsona: true
                });
                console.log('menu children => ', res);
                let categories = res;
                // [temporary] if apc card is active do not show double deal
                // if (categories && categories.length > 0 && (this.cartService.hasApcCategory() || apcCardCode)) {
                //   categories = categories.filter(category => category.id !== 'double-deal')
                // }
                this.appendToMenu('Menu', [
                    // {
                    //   id: 'e-gift-certificate', order_column: res.length ? res.length + 1 : 1, slug: '/e-gift-certificate/1', title: 'E-Gift Certificate', type: 'e-gift-certificate'
                    // }, 
                    ...categories
                ]);
            }
            catch (err) {
                console.log(err);
                this.apiService.showErrors(err);
            }
        });
    }
    getProvinces() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const branchProvinces = yield this.apiService.request({
                    method: 'GET',
                    url: '/province-branches-for-location-page',
                    jsona: true
                });
                const filteredBranchProvinces = branchProvinces.filter(branchProvince => branchProvince.branches.length);
                console.log(filteredBranchProvinces);
                this.appendToMenu('Stores', filteredBranchProvinces);
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    appendToMenu(title, arr) {
        this.menus.map(menu => {
            if (menu.title === title) {
                menu['children'] = [...arr];
            }
        });
    }
    toggleMenu(state) {
        this.menuState = state;
        if (state) {
            this.renderer.addClass(document.body, 'modal-open');
        }
        else {
            this.renderer.removeClass(document.body, 'modal-open');
        }
    }
    // checkCookieConsent() {
    //   if (document.cookie.indexOf('cookieconsent_status') > -1) {
    //     this.renderer.addClass(document.body, 'cookie-dismiss');
    //   }
    //   this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
    //     (event: NgcStatusChangeEvent) => {
    //       this.renderer.addClass(document.body, 'cookie-dismiss');
    //     });
    // }
    storageDataInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.userData.token = yield this.storeService.getItem('token');
            this.userData.user = yield this.storeService.getItem('user');
            this.branchLocationService.setUserBranchLocation();
            this.branchLocationService.setServiceBranch();
            yield this.userService.getApcCardNumber();
        });
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modalComponent = this.modalService.open(ConfirmDialogComponent, {
                ariaLabelledBy: 'modal-basic-title',
                windowClass: 'confirm-dialog-modal',
                centered: true
            });
            modalComponent.componentInstance.title = '';
            modalComponent.componentInstance.buttonText.accept = 'Yes';
            modalComponent.componentInstance.buttonText.cancel = 'No';
            modalComponent.componentInstance.confirmationMessage = 'Are you sure you want to logout?';
            modalComponent.result.then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (res) {
                    const logoutAction = true;
                    this.userService.logout(logoutAction);
                    this.router.navigate(['/']);
                }
                else {
                    return;
                }
            }));
        });
    }
    // Cart Functions
    toggleCart(open) {
        this.isCartOpen = open ? open : !this.isCartOpen;
        this.cartService.cartHeaderSubject.next(open ? open : this.isCartOpen);
        const token = this.storeService.getItem('token');
        if (!this.cartService.cartStorage && token) {
            this.cartService.getCartItems();
        }
    }
    closeCart(cartState) {
        this.isCartOpen = cartState;
    }
    emptyCart() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.cartService.cartStorage) {
                return;
            }
            if (!this.cartService.cartStorage.length) {
                return;
            }
            const modalComponent = this.modalService.open(ConfirmDialogComponent, {
                ariaLabelledBy: 'modal-basic-title',
                windowClass: 'confirm-dialog-modal',
                centered: true
            });
            modalComponent.componentInstance.title = 'Empty Cart';
            modalComponent.componentInstance.confirmationMessage = 'Are you sure you want to remove ALL items in your cart?';
            modalComponent.result.then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (res) {
                    if (this.userData.token) {
                        try {
                            this.loaderService.showAsyncLoader();
                            yield this.cartService.removeAllCartItems();
                            this.loaderService.hideAsyncLoader();
                        }
                        catch (err) {
                            console.log(err);
                            this.loaderService.hideAsyncLoader();
                        }
                    }
                    this.cartService.emptyCart();
                    if (this.router.url.includes('checkout') || this.router.url.includes('review-order')) {
                        this.router.navigate(['menu/1']);
                    }
                    this.toggleCart();
                }
            }));
        });
    }
    swUpdateChecker() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                if (confirm('New version available. Load New Version?')) {
                    window.location.reload();
                }
            });
        }
    }
    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }
}
