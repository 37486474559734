<div class="modal-body">
  <div class="popup-container w-auto border-radius-primary overflow-hidden">
    <div class="popup-innercontainer text-center inputstyle">
      <div class="popup-close font-thin mb-5">
        <a (click)="ngbModal?.dismissAll()">Close (x)</a>
      </div>
      <div class="px-5" *ngIf="isShowCardInput">
        <h6 class="mb-3">Enter your APC card number</h6>
        <span
          class="invalid-feedback text-invalid"
          [class.show]="
            (apcCard?.hasError('invalid') || apcCard?.hasError('minlength') || apcCard?.hasError('required')) &&
            this.apcCard.touched
          "
        >
          {{
            apcCard?.hasError("required")
              ? "Value is required"
              : apcCard?.hasError("minlength")
              ? "Invalid APC card number"
              : apcCard?.hasError("invalid")
              ? "Enter numeric value only"
              : ""
          }}
        </span>
        <div class="form-group mt-2">
          <input
            type="text"
            digitOnly
            class="width-100"
            [formControl]="apcCard"
          />
        </div>
        <div class="form-group">
          <a class="buttonstyle width-100" (click)="saveApcCardNumber()"
            >Proceed to ordering page</a
          >
        </div>
        <div class="form-group" *ngIf="withQuestions">
          <a class="buttonstyle buttonstyle-danger width-100" (click)="back()">Go Back</a>
        </div>
      </div>

      <div class="px-5 mb-5" *ngIf="withQuestions && !isShowCardInput">
        <h6 class="mb-4">Do you have an APC <br />(Food Ordering Club) card?</h6>

        <div class="form-group">
          <a class="buttonstyle width-100" (click)="answer('yes')"
            >Yes, I want to use it</a
          >
        </div>
        <div class="form-group">
          <a class="buttonstyle width-100" (click)="answer('avail')"
            >No, but I want to avail one</a
          >
        </div>
        <div class="form-group">
          <a class="buttonstyle buttonstyle-success width-100" (click)="answer('no')">No, proceed to ordering</a>
        </div>
      </div>
    </div>
  </div>
</div>
