import * as tslib_1 from "tslib";
import { ConfirmDialogComponent } from './../../components/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { FormGroup } from '@angular/forms';
import { SelectBranchComponent } from './../../components/select-branch/select-branch.component';
import { isPlatformBrowser } from '@angular/common';
import { OnInit, ElementRef, Renderer2 } from '@angular/core';
export class HomeComponent {
    // @ViewChild('aboutBlock', { static: false }) aboutBlock: ElementRef;
    constructor(apiService, toastService, cartService, storeService, loaderService, title, scrollListenerService, modalService, platformId, appService, renderer, elementRef, branchLocationService) {
        this.apiService = apiService;
        this.toastService = toastService;
        this.cartService = cartService;
        this.storeService = storeService;
        this.loaderService = loaderService;
        this.title = title;
        this.scrollListenerService = scrollListenerService;
        this.modalService = modalService;
        this.platformId = platformId;
        this.appService = appService;
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.branchLocationService = branchLocationService;
        this.swiperConfig = {
            bannerSlider: {
                lazy: true,
                preloadImages: false,
                loop: true,
                slidesPerView: 1,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    hideOnClick: false
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
            },
        };
        this.hasStoredLocation = false;
        this.disableDropdownButtons = false;
        this.savedSuccess = false;
        this.scrollConfig = {
            scrollHeight: 1,
            scrollTop: 0
        };
    }
    ngOnInit() {
        let siteTitle = 'Home';
        if (this.appService.siteName) {
            siteTitle = `${siteTitle} - ${this.appService.siteName}`;
        }
        this.title.setTitle(siteTitle);
        this.homePageInit();
    }
    homePageInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (isPlatformBrowser(this.platformId)) {
                this.user = yield this.storeService.getItem('user');
                yield this.getHomeSliders(),
                    this.getAboutHomeBanner(),
                    this.getHomePage(),
                    this.getArticles();
                this.loaderService.hide();
                yield Promise.all([
                    this.cartService.getCartItems(),
                ]);
                window.scrollTo(0, 0);
                let loaded = false;
                this.subscription = this.scrollListenerService.scrollListenerSubject.subscribe(scrollTop => {
                    this.scrollConfig.scrollTop = scrollTop;
                    // get scroll max height
                    // 82 as footer offset
                    this.scrollConfig.scrollHeight = (document.documentElement.scrollHeight - window.innerHeight) - 82;
                    if (scrollTop > 0 && !loaded) {
                        // this.getProducts();
                        this.getHomeDetails();
                        loaded = true;
                        // this.subscription.unsubscribe();
                    }
                });
            }
        });
    }
    getHomeSliders() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.apiService.request({
                    method: 'GET',
                    url: '/sliders/homepage',
                    jsona: true
                });
                this.homeBanners = res;
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    getAboutHomeBanner() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.apiService.request({
                    method: 'GET',
                    url: '/sliders/about-homepage',
                    jsona: true
                });
                this.aboutHomeBanner = res;
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    getHomePage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.apiService.request({
                    method: 'GET',
                    url: '/settings/food-ordering-homepage',
                });
                console.log('[getHomePage] res =>', res);
                this.homePage = res;
                if (this.homePage.embed_url) {
                    this.homePage.embed_url = this.homePage.embed_url.substring(this.homePage.embed_url.lastIndexOf('/') + 1);
                    this.homePage.embed_url = `<lite-youtube videoid="${this.homePage.embed_url}" style="max-width: 100%;"></lite-youtube>`;
                }
                console.log('home page =>', this.homePage);
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    getAbout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.apiService.request({
                    method: 'GET',
                    url: '/value-store/about'
                });
                this.about = res['company_profile_content'];
                this.aboutVideoUrl = res['history_video_embed'];
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    showBranchList() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const branch = yield this.storeService.getItem('branch');
            const branchLocation = yield this.storeService.getItem('branchLocation');
            if (branch && branchLocation) {
                return;
            }
            const user = yield this.storeService.getItem('user');
            const modalComponent = this.modalService.open(SelectBranchComponent, {
                ariaLabelledBy: 'modal-basic-title',
                windowClass: 'postcode-confirmation-modal',
                size: 'lg'
                // centered: true
            });
            modalComponent.componentInstance.user = user;
        });
    }
    removeDisabledState() {
        const isCartStorageArr = Array.isArray(this.cartService.cartStorage);
        if (!isCartStorageArr || !this.cartService.cartStorage['length']) {
            this.disableDropdownButtons = false;
            return;
        }
        const modalCtrl = this.modalService.open(ConfirmDialogComponent, {
            ariaLabelledBy: 'modal-basic-title',
            windowClass: 'postcode-confirmation-modal',
            size: 'lg',
            centered: true
        });
        modalCtrl.componentInstance.confirmationMessage = `
      Product availability may vary if you change your current location.
      <br/><br/>
      Do you want to proceed?
    `;
        modalCtrl.componentInstance.title = 'Notice';
        modalCtrl.componentInstance.buttonText.accept = 'Change Barangay';
        modalCtrl.result.then(res => {
            if (!res) {
                return;
            }
            this.disableDropdownButtons = false;
        });
    }
    setSelectedBranch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.branchForm.valid) {
                return;
            }
            try {
                const selectedBranch = this.branchForm.get('selected_branch').value;
                const user = yield this.storeService.getItem('user');
                yield this.storeService.setItem('branchLocation', this.branchForm.value);
                yield this.storeService.setItem('brgy_segment', this.branchForm.value.branch.segment);
                this.loaderService.showAsyncLoader();
                const storedBranchSegment = yield this.storeService.getItem('branch');
                if (storedBranchSegment !== selectedBranch.segment) {
                    if (Array.isArray(this.cartService.cartStorage)) {
                        if (this.cartService.cartStorage.length) {
                            if (user) {
                                yield this.cartService.removeAllCartItems();
                            }
                            else {
                                yield this.cartService.emptyCart();
                            }
                        }
                    }
                }
                yield this.storeService.setItem('branch', selectedBranch.segment);
                this.savedSuccess = true;
                setTimeout(() => {
                    this.loaderService.hideAsyncLoader();
                    window.location.reload();
                }, 500);
            }
            catch (err) {
                this.loaderService.hideAsyncLoader();
                this.toastService.showDangerToast('Failed to change branch, Please try again.');
                console.log(err);
            }
        });
    }
    getHomeDetails() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.apiService.request({
                    method: 'GET',
                    url: '/value-store/home-detail'
                });
                this.homeDetails = res;
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    ngAfterViewInit() {
        // this.elementRef.nativeElement.ownerDocument.body.style.backgroundImage = 'url("../../../assets/imgs/angels/background-home.jpg")';
        // this.elementRef.nativeElement.ownerDocument.body.style.backgroundPosition = 'top center';
        // this.elementRef.nativeElement.ownerDocument.body.style.backgroundSize = 'cover';
        // this.formatAboutBlock();
    }
    navigate(link) {
        if (!link) {
            return;
        }
        window.location.href = link;
    }
    // formatAboutBlock() {
    //   // limit html tags to three
    //   let children = this.aboutBlock.nativeElement.children;
    //   for (const [i, child] of Object.entries(children)) {
    //     if (parseInt(i) > 3) {
    //       this.renderer.removeChild(this.aboutBlock.nativeElement, child);
    //     }
    //   }
    // }
    getArticles(page) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.apiService.request({
                    method: 'GET',
                    url: `/articles?include=media&limit=2&page=${page || 1}&filter[homepage]=1`,
                    jsona: true
                });
                console.log('res =>', res);
                if (res && res.length && res.length > 0) {
                    this.articles = res;
                }
                console.log('articles =>', res);
                this.loaderService.hide();
            }
            catch (err) {
                this.loaderService.hide();
                console.log(err);
                this.apiService.showErrors(err);
            }
        });
    }
}
