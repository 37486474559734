import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Injectable } from '@angular/core';
import Jsona from 'jsona';

const dataFormatter = new Jsona();
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  // API BASE_URL
  static BASE_URL_API = 'https://api.staging.foodorderingsystem.halcyondigitalhost.com/api';
  // LOGIN BODY CONFIGß
  static LOGIN_CLIENT_KEYS = {
    ID_PASSWORD: 3,
    ID_FACEBOOK: 291429762047995,
    SECRET: 'pDfXAreNeorYUvctaNyqzfuhWy6J926IRuNOmjAC',
    SECRET_FACEBOOK: '15bff5b01ebc8ff3d2bf9e9c86824b69'
  };

  static BASE_URL = 'https://staging.foodorderingsystem.halcyondigitalhost.com';

  // FIELD REGEX
  // tslint:disable-next-line: max-line-length
  static EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Swiper Config
  static DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal'
  };

  static RE_CAPTCHA_CONFIG = {
    enabled: false,
    siteKey: '6LeoBLQZAAAAAN2pOtaUxQVBuBuk8FPI0QmBgvY6',
  }

  static PHONE_NUMBER = '09176248446';
  static FEATURES = {
    egc: false
  }

  constructor() { }

  getApiErrors(errResponse): Array<string> {
    if (errResponse.status === 422) {
      const errObj = errResponse.error.errors;
      const errorKeys = Object.keys(errObj);
      const errorValues = [];
      errorKeys.filter(errorKey => {
        errorValues.push(errObj[errorKey][0]);
      });
      return errorValues;
    }
    if (errResponse.status === 401) {
      return ['Unauthorize request'];
    }
    if (errResponse.status === 500) {
      return ['Server Error. Please try again later.'];
    }
  }

  static REGEX_PATTERNS = {
    phoneNumber: /^(09|\+639)\d{9}$/,
    telephoneNumber: /^(02)(\d{7}|\d{8})$/,
  }

}
