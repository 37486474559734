import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog/confirm-dialog.component";
export class ApcCardInputModalComponent {
    constructor(userService, ngbModal, apiService, storeService, cartService, loaderService, modalService) {
        this.userService = userService;
        this.ngbModal = ngbModal;
        this.apiService = apiService;
        this.storeService = storeService;
        this.cartService = cartService;
        this.loaderService = loaderService;
        this.modalService = modalService;
        this.apcBuyUrl = "/menu/1";
        this.apcCard = new FormControl("", [
            Validators.required,
            Validators.pattern("^[0-9]*$"),
            Validators.minLength(3),
        ]);
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // const res: any = await this.apiService.request({
            //   method: "GET",
            //   url: "/value-store/apc_card",
            // });
            // console.log("apc_card => ", res);
            // const apcCardCategory: string = res.apc_card_category || undefined;
            // console.log("apc_card_category => ", apcCardCategory);
            const apcCardCategory = this.cartService.buytake1Details.apc_card_category;
            console.log('apcCardCategory =>', apcCardCategory);
            if (!apcCardCategory)
                return;
            const branch = yield this.storeService.getItem("branch");
            let headers = {};
            headers = {
                "x-branch": branch || "all",
            };
            // let url = `/v2/products?orderBy=order_column&limit=1&page=1`;
            let url = `/v2/products?sort=order_column&limit=1&page=1`;
            if (apcCardCategory) {
                // url = `${url}&search=${apcCardCategory}&searchFields=productCategories.segment`;
                url = `${url}&filter[productCategories.segment]=${apcCardCategory}`;
            }
            const productsRes = yield this.apiService.request({
                method: "GET",
                url,
                headers,
            });
            console.log("apc url => ", productsRes);
            if (!productsRes.data)
                return;
            this.apcBuyUrl =
                productsRes && productsRes.data && productsRes.data.length > 0
                    ? `/menu/product/${productsRes.data[0].id}`
                    : this.apcBuyUrl;
            // this.apcCardCategory = res.apc_card_category;
            console.log("apc url => ", this.apcBuyUrl);
        });
    }
    saveApcCardNumber() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                console.log("saving...");
                if (!this.apcCard.valid) {
                    return;
                }
                if (this.cartService.hasProducts()) {
                    const modalComponent = this.modalService.open(ConfirmDialogComponent, {
                        ariaLabelledBy: 'modal-basic-title',
                        windowClass: 'confirm-dialog-modal',
                        centered: true
                    });
                    modalComponent.componentInstance.title = '';
                    modalComponent.componentInstance.buttonText.accept = 'Yes';
                    modalComponent.componentInstance.buttonText.cancel = 'No';
                    modalComponent.componentInstance.confirmationMessage = 'Adding APC Card would reset your cart and activate buy 1 take 1, do you want to proceed?';
                    const res = yield modalComponent.result;
                    if (!res)
                        return;
                }
                yield this.userService.setApcCardNumber(this.apcCard.value);
                this.apcCard.reset();
                console.log("saved");
                yield this.emptyCart();
                // window.location.reload();
            }
            catch (err) {
                console.log(err);
            }
        });
    }
    answer(value) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            switch (value) {
                case "yes":
                    this.isShowCardInput = true;
                    break;
                case "avail":
                    window.location.href = this.apcBuyUrl;
                    break;
                default:
                    console.log('default');
                    yield this.emptyCart();
                    break;
            }
        });
    }
    back() {
        this.withQuestions = true;
        this.isShowCardInput = false;
    }
    emptyCart() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.cartService.cartStorage || !this.cartService.cartStorage.length) {
                window.location.reload();
                return;
            }
            const token = (yield this.storeService.getItem("token")) || null;
            if (token) {
                try {
                    this.loaderService.showAsyncLoader();
                    yield this.cartService.removeAllCartItems();
                    this.loaderService.hideAsyncLoader();
                }
                catch (err) {
                    console.log(err);
                    this.loaderService.hideAsyncLoader();
                }
            }
            yield this.cartService.emptyCart();
            window.location.reload();
        });
    }
}
