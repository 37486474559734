<!-- <footer class="footer">
  <p>Copyright &copy; {{year}} Wing Zone.</p>
  <p>All Rights Reserved</p>
  <ul class="footer-nav list-unstyled">
    <li>
      <a routerLink="/">Home</a>
    </li>
    <li>
      <a routerLink="/about-us">About</a>
    </li>
    <li>
      <a routerLink="/menu/1">Menu</a>
    </li>
    <li *ngIf="location.segment && location.title">
      <a [routerLink]="['locations', location.segment]">Locations</a>
    </li> 
    <li>
      <a routerLink="/flavors">Flavors</a>
    </li>
    <li>
      <a routerLink="/contact-us">Contact</a>
    </li>
    <li>
      <a routerLink="/privacy-policy">Privacy Policy</a>
    </li>
    <li>
      <a routerLink="/terms-condition">Terms & Conditions</a>
    </li>
  </ul>
  <ul class="footer-social list-unstyled">
    <li>
      <a target="_blank" rel="noopener" [routerLink]="apiService?.socialLinks?.facebook" aria-label="Facebook">
          <span class="custom-icon-fb"></span>
      </a>
    </li>
    <li>
      <a target="_blank" rel="noopener" [routerLink]="apiService?.socialLinks?.instagram" aria-label="Instagram">
          <span class="custom-icon-ig"></span>
      </a>
    </li>
    <li>
      <a target="_blank" rel="noopener" [routerLink]="apiService?.socialLinks?.twitter" aria-label="Twitter">
          <span class="custom-icon-twitter"></span>
      </a>
    </li>
  </ul>
</footer> -->
<!-- <footer
[class.bg-transparent]="
  currentRoute === '/' ||
  currentRoute === '/login' ||
  currentRoute === '/register'
"
[defaultImage]="'/assets/imgs/angels/bg-popup.png' | image: 'relative'"
[lazyLoad]="'/assets/imgs/angels/footer-background.png' | image: 'relative'"
> -->
<footer
  [defaultImage]="
    '/assets/imgs/angels/bg-popup.png' | image: 'relative'
  "
  [lazyLoad]="
    '/assets/imgs/angels/footer-background.png' | image: 'relative'
  "
  
>
  <div class="container">
    <div class="footer-image">
      <img
        class="img-fluid"
        [defaultImage]="'/assets/imgs/angels/bg-popup.png' | image: 'relative'"
        [lazyLoad]="'assets/imgs/angels/footer-image.png' | image: 'relative'"
        alt="Footer Image"
      />
    </div>
    <div class="row">
      <div class="col-md-5">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-6">
            <ul class="font-normal-bold">
              <li><a routerLink="/" hidden></a></li>
              <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
              <li>
                <a routerLink="/terms-condition">Terms &amp; Conditions</a>
              </li>
              <!-- <li *ngIf="branchLocationService?.defaultStoresUrl"><a [routerLink]="branchLocationService?.defaultStoresUrl">Franchising</a></li> -->
              <!-- <li><a routerLink="/">Careers</a></li> -->
            </ul>
          </div>
          <div class="col-md-6 col-sm-6 col-6">
            <ul class="font-normal-bold">
              <li><a routerLink="/menu/1">Menu</a></li>
              <li *ngIf="location?.segment">
                <a [routerLink]="['locations']">Stores</a>
              </li>
              <!-- <li><a routerLink="/">Promos</a></li> -->
              <li><a routerLink="/apc-card">APC Card</a></li>
            </ul>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <div class="payment-icons">
              <img
                [defaultImage]="
                  '/assets/imgs/angels/bg-popup.png' | image: 'relative'
                "
                [lazyLoad]="
                  '/assets/imgs/angels/payment1.png' | image: 'relative'
                "
                alt="Payment 1"
              />
              <img
                [defaultImage]="
                  '/assets/imgs/angels/bg-popup.png' | image: 'relative'
                "
                [lazyLoad]="
                  '/assets/imgs/angels/payment2.png' | image: 'relative'
                "
                alt="Payment 2"
              />
              <img
                [defaultImage]="
                  '/assets/imgs/angels/bg-popup.png' | image: 'relative'
                "
                [lazyLoad]="
                  '/assets/imgs/angels/payment3.png' | image: 'relative'
                "
                alt="Payment 3"
              />
              <img
                [defaultImage]="
                  '/assets/imgs/angels/bg-popup.png' | image: 'relative'
                "
                [lazyLoad]="
                  '/assets/imgs/angels/payment4.png' | image: 'relative'
                "
                alt="Payment 4"
              />
              <div class="ml-3">Cash on delivery</div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <div class="socials-a">
              <ng-container *ngFor="let social of socialLinks | keyvalue">
                <a [href]="social?.value" target="_blank" *ngIf="social?.value"
                  ><span [class]="'custom-icon-' + social?.key"></span
                >
              </a>
              </ng-container>
              <!-- <a href="https://www.facebook.com/angelspizzaph" target="_blank"
                ><span class="custom-icon-facebook"></span
              ></a>
              <a href="https://www.instagram.com/angelspizzaph/" target="_blank"
                ><span class="custom-icon-instagram"></span
              ></a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <div
          class="
            row
            mt-4
            justify-content-center justify-content-md-start
            mt-lg-0
          "
        >
          <div class="">
            <h4 class="footer-heading">
              Get exclusive<br />Offers &amp; Discounts
            </h4>
          </div>
          <div class="">
            <div class="emailform">
              <form class="pt-2 px-2">
                <input
                  type="text"
                  placeholder="Enter email"
                  class="font-thin mb-2"
                  [formControl]="email"
                /><button
                  id="sendButton"
                  style="border: none; background: none"
                  class="mb-2"
                  (click)="subscribe()"
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-7">
				<div class="row mt-4 mt-lg-0">
					<div class="col-md-6 col-sm-12">
						<h4 class="footer-heading">Get exclusive<br>Offers &amp; Discounts</h4>
					</div>
					<div class="col-md-6 col-sm-12">
						<div class="emailform">
							<form class="pad-top-sm">
								<input type="text" placeholder="Enter email" class="font-thin" [formControl]="email"><button id="sendButton" style="border:none; background: none; color: white;" (click)="subscribe()">Send</button>
							</form>
						</div>
					</div>
				</div>
			</div> -->
    </div>
    <!-- <div class="footer-image"> -->
    <!-- <img class="img-fluid footer-image" [lazyLoad]="'assets/imgs/angels/footer-image.png' | image: 'relative'" alt="Footer Image"> -->
    <!-- </div> -->
    <!-- <div class="footer-image">
			<img class="img-fluid" 
      [defaultImage]="
        '/assets/imgs/angels/bg-popup.png' | image: 'relative'
      " [lazyLoad]="'assets/imgs/angels/footer-image.png' | image: 'relative'" alt="Footer Image">
		</div> -->
  </div>
</footer>
