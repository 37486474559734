import { InputQtyComponent } from './input-qty/input-qty.component';
import { DirectivesModule } from './../directives/directives.module';
import { PipesModule } from './../pipes/pipes.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuItemSelectComponent } from './menu-item-select/menu-item-select.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';

import {
    NgbDropdownModule,
    NgbCollapseModule,
    NgbTabsetModule,
    NgbAlertModule,
    NgbToastModule
  } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BranchComponent } from './branch/branch.component';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog/confirm-dialog.component';
import { SocialShareModalComponent } from './social-share-modal/social-share-modal/social-share-modal.component';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { ItemCommentComponent } from './item-comment/item-comment.component';
import { ToastContainerComponent } from './toast-container/toast-container.component';
import { SelectBranchComponent } from './select-branch/select-branch.component';
import { EditBranchComponent } from './edit-branch/edit-branch.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { LocationWidgetComponent } from './location-widget/location-widget.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { CartComponent } from './cart/cart.component';
import { LocationWidgetModalComponent } from './location-widget-modal/location-widget-modal.component';
import { ChiliCountComponent } from './chili-count/chili-count.component';
import { BannerComponent } from './banner/banner.component';
import { PromoCodeModalComponent } from './promo-code-modal/promo-code-modal.component';
import { FilterComponentPipe } from '../pipes/filter/filter.pipe';
import { MenuEgcComponent } from './menu-egc/menu-egc.component';
import { RegisterModule } from '../pages/register/register.module';
import { LoginModule } from '../pages/login/login.module';
import { ApcCardInputComponent } from './apc-card-input/apc-card-input.component';
import { ApcCardInputModalComponent } from './apc-card-input-modal/apc-card-input-modal.component';
import { CartContentComponent } from './cart-content/cart-content.component';
import { BranchDropdownComponent } from './branch-dropdown/branch-dropdown.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {CollapseModule} from 'ngx-bootstrap/collapse'
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MenuItemComponent,
    MenuItemSelectComponent,
    BranchComponent,
    BackToTopComponent,
    ConfirmDialogComponent,
    SocialShareModalComponent,
    InformationDialogComponent,
    ItemCommentComponent,
    ToastContainerComponent,
    SelectBranchComponent,
    EditBranchComponent,
    ProductItemComponent,
    LocationWidgetComponent,
    InputQtyComponent,
    LocationWidgetComponent,
    SidemenuComponent,
    CartComponent,
    LocationWidgetModalComponent,
    ChiliCountComponent,
    BannerComponent,
    PromoCodeModalComponent,
    MenuEgcComponent,
    ApcCardInputComponent,
    ApcCardInputModalComponent,
    CartContentComponent,
    BranchDropdownComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SwiperModule,
    PipesModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    RegisterModule,
    LoginModule,

    // BOOTSTRAP MODULES
    NgbDropdownModule,
    NgbCollapseModule,
    NgbTabsetModule,
    NgbAlertModule,
    NgbToastModule,
    LazyLoadImageModule,

    CollapseModule.forRoot()
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    MenuItemComponent,
    MenuItemSelectComponent,
    ConfirmDialogComponent,
    ToastContainerComponent,
    ItemCommentComponent,
    BackToTopComponent,
    InformationDialogComponent,
    SocialShareModalComponent,
    BranchComponent,
    EditBranchComponent,
    ProductItemComponent,
    LocationWidgetComponent,
    InputQtyComponent,
    LocationWidgetComponent,
    SidemenuComponent,
    CartComponent,
    ChiliCountComponent,
    BannerComponent,
    PromoCodeModalComponent,
    MenuEgcComponent,
    ApcCardInputComponent,
    ApcCardInputModalComponent,
    CartContentComponent,
    BranchDropdownComponent,
  ],
  entryComponents: [
    MenuItemComponent,
    MenuItemSelectComponent,
    ConfirmDialogComponent,
    ItemCommentComponent,
    InformationDialogComponent,
    SocialShareModalComponent,
    SelectBranchComponent,
    LocationWidgetModalComponent,
    PromoCodeModalComponent,
    ApcCardInputModalComponent
  ],
  providers: [
    FilterComponentPipe,
  ]
})
export class ComponentsModule { }
