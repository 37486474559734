import * as tslib_1 from "tslib";
import { FormValidationService } from './../../services/validation/form-validation.service';
import { isPlatformBrowser } from '@angular/common';
import { GlobalService } from './../../services/global.service';
import { OnInit, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { FacebookLoginProvider } from 'angularx-social-login';
import * as moment from 'moment';
export class LoginComponent {
    constructor(formBuilder, authService, apiService, storageService, toastService, cartService, router, userService, formdataService, loaderService, title, branchLocationService, appService, platformId) {
        this.formBuilder = formBuilder;
        this.authService = authService;
        this.apiService = apiService;
        this.storageService = storageService;
        this.toastService = toastService;
        this.cartService = cartService;
        this.router = router;
        this.userService = userService;
        this.formdataService = formdataService;
        this.loaderService = loaderService;
        this.title = title;
        this.branchLocationService = branchLocationService;
        this.appService = appService;
        this.platformId = platformId;
        this.isShowPass = false;
        this.closeModal = new EventEmitter();
        this.openSignUpModal = new EventEmitter();
        this.credentialsForm = this.formBuilder.group({
            username: ['', Validators.compose([
                    FormValidationService.NoWhitespaceValidator,
                    Validators.pattern(GlobalService.EMAIL_REGEX),
                    Validators.maxLength(50),
                    Validators.required,
                ])],
            password: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
            client_id: '3',
            client_secret: '7bQMIiFRjxwta6ZfUUWVvsdo1q9x1kni76oLVaxm'
        });
        let siteTitle = 'Login';
        if (this.appService.siteName) {
            siteTitle = `${siteTitle} - ${this.appService.siteName}`;
        }
        this.title.setTitle(siteTitle);
    }
    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                this.loaderService.hide();
            }, 100);
        }
    }
    login(isFbLogin, accessToken) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.credentialsForm.valid || isFbLogin) {
                this.loaderService.showAsyncLoader();
                try {
                    const headers = {
                        Authorization: `Basic ${btoa(`${GlobalService.LOGIN_CLIENT_KEYS.ID_PASSWORD}:${GlobalService.LOGIN_CLIENT_KEYS.SECRET}`)}`
                    };
                    const reqBody = Object.assign({}, this.credentialsForm.value, { grant_type: isFbLogin ? 'social' : 'password', 
                        // client_id: GlobalService.LOGIN_CLIENT_KEYS.ID_PASSWORD,
                        // client_secret: GlobalService.LOGIN_CLIENT_KEYS.SECRET,
                        provider: isFbLogin ? 'facebook' : 'none' });
                    if (isFbLogin) {
                        reqBody.access_token = accessToken;
                    }
                    const loginRes = yield this.apiService.postRequest({
                        url: '/oauth/token',
                        body: this.formdataService.generate(reqBody),
                        headers
                    });
                    yield this.storageService.setItem('token', loginRes.access_token);
                    // user app wide update
                    const isUpdate = true;
                    const user = yield this.userService.getUser(isUpdate, isFbLogin);
                    if (user) {
                        const userKeys = Object.keys(user);
                        if (userKeys.includes('has_verified_email')) {
                            if (!user.has_verified_email) {
                                this.loaderService.hideAsyncLoader();
                                this.storageService.removeItem('token');
                                this.toastService.showDangerToast('Failed to login. Please verify your email to continue.');
                                return;
                            }
                        }
                    }
                    yield this.branchLocationService.setUserBranchLocation(true); // specify `true` as login page execution
                    yield this.storageService.setItem('login_timestamp', moment().format());
                    yield this.storageService.setItem('refresh_token', loginRes.refresh_token);
                    yield this.storageService.setItem('social_login', isFbLogin ? true : false);
                    const ignoreToast = true;
                    this.cartService.emptyCart(ignoreToast);
                    this.loaderService.hideAsyncLoader();
                    if (loginRes.message) {
                        this.toastService.showWarningToast(loginRes.message, 8000);
                    }
                    else {
                        this.toastService.showSuccessToast('Successfully logged in.');
                        this.closeModal.emit();
                        this.router.navigate(['/']);
                    }
                    // const branchLocation = await this.storageService.getItem('branchLocation');
                    // if (branchLocation) {
                    //   await this.storageService.removeItem('branchLocation');
                    // }
                }
                catch (err) {
                    if (err.status === 400) {
                        this.loaderService.hideAsyncLoader();
                        this.toastService.showDangerToast(`Login Failed. Wrong email or password.`);
                        return;
                    }
                    if (err.status !== 401) {
                        console.log(err);
                        this.loaderService.hideAsyncLoader();
                        const hasMessageKey = Object.keys(err.error).includes('message');
                        if (hasMessageKey) {
                            this.toastService.showDangerToast(err.error.message);
                            return;
                        }
                        const errorFields = Object.keys(err.error);
                        errorFields.filter(key => {
                            err.error[key].filter(errMessage => {
                                this.toastService.showDangerToast(errMessage);
                            });
                        });
                        console.log(err);
                    }
                    else {
                        this.loaderService.hideAsyncLoader();
                    }
                }
            }
        });
    }
    loginWithFb() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const socialRes = yield this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
                console.log(socialRes);
                const isFbLogin = true;
                yield this.login(isFbLogin, socialRes.authToken);
            }
            catch (err) {
                this.toastService.showDangerToast('Failed to login. Please try again.');
                console.log(err);
            }
        });
    }
}
